.wizards {
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  width: 100%;
}

.progressbar {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
}

.progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: #274a70;
}

.form-wizard {
  position: relative;
  float: left;
  width: 33%;
  padding: 0 5px;
  text-align: center;
}

.form-wizard.activated .wizard-icon {
  background: #fff;
  border: 1px solid #274a70;
  color: #274a70;
  line-height: 38px;
}

.wizard-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 4px;
  background: #ddd;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

.form-wizard.active .wizard-icon {
  width: 48px;
  height: 48px;
  margin-top: 0;
  background: #274a70;
  font-size: 22px;
  line-height: 48px;
}

.contribution-add-location {
}

.contribution-add-location .icon-children {
  left: 25px;
}

.contribution-add-location button {
  padding: 9px 20px !important;
  line-height: 1.5;
  border-radius: 4px;
  background: #274a70 !important;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 100%;
  margin: 10px auto;
  padding: 20px;
}

.images-area {
  display: flex;
  flex-wrap: wrap;
}

.drop-image {
  position: relative;
  width: 20%;
}

.drop-image a {
  position: absolute;
  top: 0;
  right: 5px;
  background: red;
  width: 20px;
  height: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 100%;
  text-align: center;
}

button.btn.btn-success {
  background: #274a70 !important;
}
