.new-grid {
  width: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
  /* border: 1.5px solid blue; */
}
.new-top {
  position: relative;
  /* padding: 1.5em; */
  background: #fff;
}
.new-top a img {
  height: 333px;
  width: 100%;
}
.new-text {
  background-color: #16a085;
  padding: 1em;
  position: absolute;
  top: 25%;
  width: 80%;
  left: -100%;
  transition: 0.5s all;
}
.new-text ul li {
  display: block;
  text-align: center;
}
.new-text ul li a {
  border: 1px solid #fff;
  padding: 0.8em 1em;
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
}
.popular-item {
  margin: 0.8%;

  width: 23.4%;
  padding: 0 !important;
}
.popular-item .new-bottom {
  padding: 1em;
}

.new-bottom {
  padding: 1em;
  background: #fff;
  /* position: absolute; */
  bottom: 0px;
}
.new-bottom h5 {
  font-size: 1.6em;
  font-weight: 700;
  display: inline-block;
  height: 50px;
}
.new-bottom h5 a.name {
  color: #353f49;
}
.ofr p {
  font-size: 1.1em;
  color: #353f49;
  display: inline-block;
  margin-top: 0.7em;
  font-weight: 600;
}
.rating {
  text-align: center;
  float: right;
  margin-top: 0.7em;
}
.rating span {
  display: inline-block;
  position: relative;
  font-size: 1.3em;
  color: #555;
}
.new-grid:hover .new-text {
  left: 10%;
}
.leftMenuArea > h2 {
  font-size: 14px;
  font-weight: 400;
  color: #444546;
  text-align: left;
  text-transform: capitalize;
  margin: 30px 0 0;
  margin-bottom: 25px;
}
