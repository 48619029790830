.c-card.br {
  /* border-right: 1px solid #ddd; */
}

.c-card {
  padding: 0 15px 20px;
}

.hotJobsCompany {
  background-color: #f5f5f5;
  cursor: pointer;
}

.hotJobsCompany {
  padding: 15px 0 10px;
  display: flex;
}

.companyDetails h5 {
  margin: 0 0 5px;
  color: #2f64a3;
  font-weight: 400;
  font-size: 13px;
}

.companyDetails ul li a {
  color: #616161;
  font-size: 12px;
  text-transform: none;
}

.cursor {
  cursor: pointer;
}

.modal-dialog {
  max-width: 1170px;
  margin: 1.75rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  width: 100%;
}

.modal-title .row {
  justify-content: space-between;
}

.header-left img {
  width: 100px;
}

h4.modal-title {
  padding: 0 35px;
}

.header-left {
  display: flex;
  align-items: center;
}

.header-left img {
  width: 198px;
}

section.study-abroad-section {
  padding-bottom: 450px;
}

.companyLogo img {
  height: 55px;
}

.hotJobsCompany {
  margin-bottom: 20px;
}
