@media only screen and (max-width: 480px) {
  .info-find-job {
    flex-direction: column;
  }

  .apply-btn-area {
    flex-direction: row !important;
  }

  .d {
    margin-top: 25px;
  }
}

main.main-content {
  min-height: 54vh;
}

.custom-select,
.form-control {
  border-width: 2px;
}

@media only screen and (max-width: 768px) {
  section.section-padding-20.tutors-section {
    padding: 20px 20px;
  }

  .search-input input {
  }
}
