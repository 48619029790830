@import url(https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i|Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0;
}

img {
  max-width: 100%;
}

body .theme-solid-button {
  line-height: 48px;
  border-width: 1px;
  border-radius: 20px;
  padding: 12px 40px;
  font-size: 18px;
  border-style: solid;
  border-color: #274a70;
  color: #fff;
  background: #274a70;
  transition: all ease-in-out 0.4s;
}

.navbar-nav {
  -webkit-align-items: center;
          align-items: center;
}

body .theme-line-button {
  line-height: 48px;
  border-width: 1px;
  border-radius: 20px;
  padding: 0 40px;
  font-size: 18px;
  color: #274a70;
  border-style: solid;
  border-color: #274a70;
  padding: 12px 40px;
  transition: all ease-in-out 0.4s;
}

body .theme-line-button:hover {
  color: #fff;
  background: #274a70;
}

.theme-solid-button:hover {
  color: #274a70;
  border-color: #274a70;
  background: transparent;
}

.section-padding {
  padding: 100px 0;
}

.mark-text {
  color: #274a70;
}

.section-header {
  margin: auto;
  text-align: center;
  padding-bottom: 40px;
}

a.theme-solid-button.nr {
  padding: 13px 20px !important;
  font-size: 15px;
  border-radius: 5px;
  line-height: 1.5;
}

.primary-bg {
  background: #274a70;
}

@media only screen and (max-width: 480px) {
  .info-find-job {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .apply-btn-area {
    -webkit-flex-direction: row !important;
            flex-direction: row !important;
  }

  .d {
    margin-top: 25px;
  }
}

main.main-content {
  min-height: 54vh;
}

.custom-select,
.form-control {
  border-width: 2px;
}

@media only screen and (max-width: 768px) {
  section.section-padding-20.tutors-section {
    padding: 20px 20px;
  }

  .search-input input {
  }
}

/* Header Scss */
.header-wrapper {
  position: relative;
}

.top-header.bg-one {
  background: #274a70;
}

.header-wrapper .top-header .left-list ul li {
  margin-right: 30px;
  position: relative;
}

.header-wrapper .top-header ul li {
  display: inline-block;
  line-height: 28px;
  vertical-align: middle;
}

.header-wrapper .top-header ul li a {
  color: #fff;
  position: relative;
}

.header-wrapper .top-header .right-list ul li {
  margin-left: 10px;
}

.header-wrapper .top-header {
  padding: 0px 0;
}

.header-wrapper .top-header .right-list ul.social-icon li {
  margin-left: 25px;
}

header .theme-menu-wrapper {
  padding: 0px 0;
}

header .theme-menu-wrapper .main-content-wrapper {
  position: relative;
}

header .theme-menu-wrapper .logo {
  margin-top: 3px;
}

#mega-menu-wrapper {
  margin: 0;
  border: none;
  border-radius: 0;
  min-height: auto;
  text-align: center;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

#mega-menu-wrapper .nav > li {
  display: inline-block;
}

#mega-menu-wrapper .nav > li > a {
}

a.waves-effect.waves-light {
  font-family: "Noto Sans", sans-serif;
  position: relative;
  font-size: 15px;
  color: #222222;
  background: transparent;
  text-transform: capitalize;
  margin: 0 12px;
  transition: all 0.3s ease-in-out;
  padding-bottom: 10px;
  padding-top: 10px;
}

.navbar {
  box-shadow: none !important;
  font-weight: 300;
}

.nav li.join-us a {
  font-family: "Source Sans Pro", sans-serif;
  color: #fff;
  padding: 0 45px;
  margin-right: 0;
  margin-left: 10px;
}

a.waves-effect.waves-light:after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 2px;
  opacity: 0;
  z-index: 99;
  transition: all 0.3s ease-in-out;
  background: #274a70;
}

a.waves-effect.waves-light:hover:after {
  opacity: 1;
  width: 100%;
}

a.waves-effect.waves-light.active:after {
  opacity: 1;
  width: 100%;
}

.navbar-brand img {
  height: 50px;
}

.control-block {
  position: relative;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.control-block .author-thumb {
  margin-right: 8px;
  position: unset;
}

.author-thumb img {
  overflow: hidden;
  max-width: unset;
  border: 2px solid #fff;
  border-radius: 100%;
  box-shadow: 0 5px 10px 4px rgba(0, 0, 0, 0.04);
}

.author-thumb .icon-status {
  position: absolute;
  margin-right: 0;
  top: 0;
  left: 0;
  border: 1px solid #3f4257;
  width: 8px;
  height: 8px;
}

.icon-status {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  display: inline-block;
  margin-right: 8px;
}

.icon-status.online {
  background-color: #32e4cd;
}

.control-block .more-dropdown {
  width: 230px;
  top: calc(100% + 5px);
  right: -30px;
  padding: 0;
}

.control-block:hover .more-dropdown {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.control-block .author-title {
  font-weight: 700;
  font-size: 12px;
  color: #fff;
  margin: 0;
}

.author-name p {
  font-size: 8px;
  color: #fff;
  margin: 0;
}

.author-name {
  margin-right: 10px;
}

.author-arrow-option {
  margin-bottom: 15px;
}

.author-arrow-option i {
  font-size: 8px;
  color: #fff;
}

.ui-block-title {
  padding: 23px 25px 18px;
  line-height: 1;
  border-bottom: 1px solid #e6ecf5;
  border-top: 1px solid #e6ecf5;
  display: table;
  width: 100%;
  position: relative;
  border-radius: 5px 5px 0 0;
}

.ui-block-title:first-child {
  border-top: none;
}

.ui-block-title.ui-block-title-small {
  padding: 10px 25px;
}

.ui-block-title.ui-block-title-small .title {
  font-size: 9px;
  text-transform: uppercase;
  color: #9a9fbf;
  margin: 0;
}

.control-block .account-settings {
  padding: 15px 25px;
  margin-bottom: 0;
}

.control-block .account-settings li a i {
  color: #9a9fbf;
  font-size: 18px;
  margin-right: 10px;
}

.control-block .account-settings li a {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }

  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

.primary-menu.mobile-top-primary-menu {
  display: none;
}

.mobile-top-Header {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between !important;
          justify-content: space-between !important;
}

.header-wrapper .btn-secondary.dropdown-toggle {
  background-color: #274a70 !important;
}

.navbar {
  padding: 0.5rem 0;
}

.header-wrapper .top-header ul li:last-child {
  margin-right: 0;
}

.row.align-items-center.mbile-ser {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}

.banner-section {
  height: 80vh;
  /* animation-name: example;
  animation-duration: 20s;
  animation-timing-function: ease-in;
  animation-iteration-count: infinite; */
  position: relative;
}

.banner-section .carousel-caption {
  bottom: inherit;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.cameraContent.cameracurrent a {
  margin: 0 20px;
}

.cameraContent.cameracurrent a:first-child {
  margin: 0;
}

.cameraContent.cameracurrent a.wow.fadeInLeft.theme-solid-button {
  margin: 0;
}

.view img,
.view video {
  height: 80vh;
}

.our-feature h2 {
  margin-top: 45px;
  font-size: 40px;
}

.our-feature .mark-text {
  font-size: 21px;
  line-height: 32px;
  padding: 35px 0 30px 0;
}

.our-feature p {
  font-size: 18px;
  line-height: 32px;
  margin-bottom: 30px;
}

.our-feature-course-one .feature-block .wrapper {
  position: relative;
  padding: 25px 0 0 115px;
}

.our-feature-course-one .feature-block .icon {
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  background: #dcdada;
  position: absolute;
  top: 0;
  left: 0;
  transition: all ease 0.5s;
}

.our-feature-course-one .feature-block .icon i {
  font-size: 58px;
  margin-left: 40px;
  display: block;
  color: #274a70;
  margin-top: 10px;
  transition: all ease 0.5s;
}

.our-feature-course-one .feature-block:hover .icon i {
  color: #fff;
  -webkit-transform: translateX(-20px);
  transform: translateX(-20px);
}

.our-feature-course-one .feature-block:hover .icon {
  background: #274a70;
}

.our-feature-course-one .feature-block p {
  line-height: 26px;
  padding-top: 35px;
}

.team-item {
  transition: all ease-in 0.4s;
  margin-bottom: 10px;
  text-align: center;
  padding: 80px 30px 50px;
  position: relative;
  overflow: hidden;
}

.team-thumb img {
  border-radius: 100%;
  transition: all ease 0.4s;
  border-radius: 100%;
  transition: all ease 0.4s;
  width: 126px;
}

.team-content-overlay {
  font-size: 18px;
  margin-bottom: 26px;
  color: #191919;
  transition: all ease-in 0.4s;
  position: absolute;
  top: 100%;
  padding-right: 5px;
}

.team-contant p {
  margin: 0;
  color: #191919;
  transition: all ease-in 0.4s;
  /* position: absolute;
  top: 100%; */
  text-align: left;
}

.team-item:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 2px;
  height: 0px;
  background-color: #000;
  transition: all ease 0.4s;
}

.team-item:hover:after {
  width: 150px;
}

.team-item:hover:before {
  height: 150px;
}

.team-item:hover .team-thumb img {
  -webkit-transform: scale(0.5) translate(-190px, -205px);
          transform: scale(0.5) translate(-190px, -205px);
}

.team-item:hover .team-contant h3,
.team-contant h4,
.team-contant p {
  color: #fff;
}

.team-item:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 0px;
  height: 2px;
  background-color: #000;
  transition: all ease 0.4s;
}

.team-item:hover {
  background: #274a70;
}

.team-item:hover .team-contant h4 {
  color: #fff;
}

.team-item:hover .team-contant .team-content-overlay {
  top: 15px;
  left: 30%;
  width: 70%;
}

.team-content-overlay a.btn.sm.white {
  color: #212529;
  padding: 7px 16px;
  margin-top: 47px;
}

.team-item:hover .team-contant p {
  color: #fff;
}

/* .team-item:hover .team-contant p {
  top: 45px;
  left: 30%;
  right: 10px;
} */

.team-item:hover .team-contant h3,
.team-contant h4,
.team-contant p {
  color: #fff;
}

.team-item:hover .team-contant h3 {
  -webkit-transform: rotate(90deg) translate(-60px, 90px);
          transform: rotate(90deg) translate(-60px, 90px);
}

.team-contant h3 {
  font-size: 19px;
  font-weight: 600;
  color: #191919;
  transition: all ease-in 0.4s;
}

.client-post-item {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #fff;
  padding: 0px 15px 10px;
  border-radius: 3px;
  box-shadow: 0 0 16px #ddd;
  padding-top: 0px;
  background: #f1f1f1;
  margin-bottom: 30px;
}

.skills {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.client-post-item .skills li {
  background: #dddd;
}

.skills li {
  padding: 3px 13px;
  margin: 5px;
  border-radius: 1px;
  font-size: 13px;
}

.job-ser {
  background: rgba(0, 0, 0, 0.1);
}

ul.job-def {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 6px 10px;
}

ul.job-def li:nth-child(1) {
  color: #388e3c;
}

ul.job-def li {
  display: -webkit-flex;
  display: flex;
  border-right: 1px solid #fff;
  padding-right: 10px;
  font-weight: 700;
}

ul.job-def li:nth-child(2) {
  color: #e64a19;
}

ul.job-def li:nth-child(3) span {
  color: #1976d2;
}

ul.job-def li:nth-child(4) {
  color: #689f38;
}

ul.job-def li:nth-child(5) {
  color: #e64a19;
}

.client-post-item button.btn.btn-outline-info.apply-btn {
  width: 179px;
}

@-webkit-keyframes example {
  0% {
    background: url(/static/media/banner-1.5eaf3790.jpg) no-repeat center center/cover;
  }

  25% {
    background: url(/static/media/banner-3.64d16e9e.jpg) no-repeat center center/cover;
  }

  50% {
    background: url(/static/media/banner-img-1.663b28af.jpg) no-repeat center center/cover;
  }

  100% {
    background: url(/static/media/banner.ba3d73d7.jpg) no-repeat center center/cover;
  }
}

@keyframes example {
  0% {
    background: url(/static/media/banner-1.5eaf3790.jpg) no-repeat center center/cover;
  }

  25% {
    background: url(/static/media/banner-3.64d16e9e.jpg) no-repeat center center/cover;
  }

  50% {
    background: url(/static/media/banner-img-1.663b28af.jpg) no-repeat center center/cover;
  }

  100% {
    background: url(/static/media/banner.ba3d73d7.jpg) no-repeat center center/cover;
  }
}

ul.developer-s-info li {
  display: -webkit-flex;
  display: flex;
  color: #fff;
  font-size: 15px;
}

ul.developer-s-info li p {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  color: #fff;
  width: 90px;
}

@media only screen and (max-width: 991px) {
  .our-feature-course-one .feature-block .wrapper {
    padding: 0;
  }

  .our-feature-course-one .feature-block .icon {
    position: inherit;
  }
}

@media only screen and (max-width: 768px) {
  section.our-feature.section-padding img {
    margin-top: 25px;
  }

  .apply-btn-area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  ul.job-def {
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
  }

  .client-post-item button.btn.btn-outline-info.apply-btn {
    width: 158px;
  }

  body .theme-solid-button {
    display: inline-block;
    line-height: inherit;
    margin-bottom: 15px !important;
  }

  .navbar.navbar-dark .navbar-toggler {
    background: #274a70;
  }

  .col-sm-7.col-xs-12.right-list.text-right {
    text-align: left !important;
  }

  .left-list ul {
    text-align: center;
  }
}

body .theme-solid-button {
  display: inline-block;
  line-height: inherit;
  margin-bottom: 15px !important;
}

@media only screen and (max-width: 480px) {
  .header-wrapper .top-header .left-list ul li {
    margin-right: 0;
  }

  .team-item {
    width: 80%;
    margin: 13px auto;
  }

  .row {
    margin: 0;
  }

  .cameraContent.cameracurrent a {
    margin: 0;
  }
}

.home-tutor .team-content-overlay a.btn.sm.white {
  margin-top: 53px !important;
  margin-right: 95px;
}

.home-find-tutor a.btn.sm.white {
  margin-right: 95px;
}

.bg-new-color {
  background: #274a70 !important;
  color: #fff;
}

.section-padding-20 {
  padding: 20px 0;
}

.header-wrapper .top-header .right-list ul.social-icon li:first-child {
  margin-left: 0;
}

.job-find-section {
  background-color: #f4f4f4;
  padding: 25px 0;
}

h3.panel-heading {
  font-size: 20px;
  line-height: 1.1;
  margin-top: 0;
  padding: 12px;
  text-align: center;
  background: #274a70;
  color: #ffffff;
  font-weight: 900;
  border-radius: 0;
}

.job-find-section .form-group label {
  color: #1f2c44;
  font-size: 16px;
  font-weight: 900;
}

.job-find-section .job_board_label {
  margin-bottom: 10px !important;
  color: #212121;
  font-weight: 500;
  font-size: 16px;
  text-align: left !important;
}

.sidebar-body {
  background: #fff;
  padding: 10px;
}

.styled-input-single {
  position: relative;
  padding: 0px 0 0px 20px;
  text-align: left;
}

.styled-input-single input[type="checkbox"] {
  position: absolute;
  top: 0;
  left: -9999px;
  visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  opacity: 1;
}

label.input-label-radio:before,
label.input-label-checkbox:before {
  left: 0;
  width: 12px;
  height: 12px;
  margin: -8px 0 0;
  background: #e2e0e0;
}

label.input-label-checkbox:before,
label.input-label-checkbox:after {
  border-radius: 0;
}

label.input-label-radio:before,
label.input-label-radio:after,
label.input-label-checkbox:before,
label.input-label-checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  border-radius: 50%;
}

.styled-input-single input[type="radio"]:checked + label.input-label-radio:after,
.styled-input-single input[type="checkbox"]:checked + label.input-label-checkbox:after {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1;
  content: "";
}

input[type="checkbox"].styled:checked + label:after,
input[type="radio"].styled:checked + label:after {
  font-family: "FontAwesome";
  content: "\F00C";
}

label.input-label-radio:after,
label.input-label-checkbox:after {
  left: 0px;
  width: 12px;
  height: 12px;
  margin: -8px 0 0;
  opacity: 0;
  background: #274a70;
  -webkit-transform: translate3d(-40px, 0, 0) scale(0.5);
  transform: translate3d(-40px, 0, 0) scale(0.5);
  transition: opacity 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out;
  transition: opacity 0.25s ease-in-out, transform 0.25s ease-in-out, -webkit-transform 0.25s ease-in-out;
}

.job-find-section .client-post-item button.btn.btn-outline-info.apply-btn {
  width: 143px;
  padding-left: 0;
  padding-right: 0;
}

.client-post-item {
  background: #fff;
}

.find-tuition-title {
  background: #e9ecef;
  padding: 6px 0;
  margin-top: 15px;
  margin-bottom: 15px;
}

.find-tuition-title h4 {
  margin-bottom: 0;
}

.apply-btn-area {
  padding-top: 36px !important;
}

.job-find-section ul.job-def li {
  display: -webkit-flex;
  display: flex;
  border-right: 1px solid #fff;
  padding-right: 10px;
  font-weight: 400;
  font-size: 12px;
}

.apply-btn-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  height: 100%;
  padding-top: 10px;
}

.job-find-section .client-post-item button.btn.btn-outline-info.apply-btn {
  width: 143px;
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}

.bold {
  font-weight: 700;
}

.find-job-button-area {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.find-job-button-area button {
  padding: 5px 10px;
}

/* [type="radio"]:not(:checked), [type="radio"]:checked {
  opacity: 1 !important;
} */
.apply-btn-area button {
  padding: 8px 0 !important;
}

.input-group-text {
  width: 128px;
}

.login-form {
  max-width: 450px;
  margin: 60px auto 30px;
}

.login-form .card-header {
  padding: 2px 1.25rem;
}

.login-form .card-body {
  padding: 20px;
}

.register-btn-area i {
  margin-right: 15px;
}

.register-btn-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.register-btn-area a.btn.theme-line-button {
  width: 270px;
}

.wizards {
  overflow: hidden;
  position: relative;
  margin-top: 20px;
  width: 100%;
}

.progressbar {
  position: absolute;
  top: 24px;
  left: 0;
  width: 100%;
  height: 1px;
  background: #ddd;
}

.progress-line {
  position: absolute;
  top: 0;
  left: 0;
  height: 1px;
  background: #274a70;
}

.form-wizard {
  position: relative;
  float: left;
  width: 33%;
  padding: 0 5px;
  text-align: center;
}

.form-wizard.activated .wizard-icon {
  background: #fff;
  border: 1px solid #274a70;
  color: #274a70;
  line-height: 38px;
}

.wizard-icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin-top: 4px;
  background: #ddd;
  font-size: 16px;
  color: #fff;
  line-height: 40px;
  border-radius: 50%;
}

.form-wizard.active .wizard-icon {
  width: 48px;
  height: 48px;
  margin-top: 0;
  background: #274a70;
  font-size: 22px;
  line-height: 48px;
}

.contribution-add-location {
}

.contribution-add-location .icon-children {
  left: 25px;
}

.contribution-add-location button {
  padding: 9px 20px !important;
  line-height: 1.5;
  border-radius: 4px;
  background: #274a70 !important;
}

.drop-area {
  border: 2px dashed #ccc;
  border-radius: 20px;
  width: 100%;
  margin: 10px auto;
  padding: 20px;
}

.images-area {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.drop-image {
  position: relative;
  width: 20%;
}

.drop-image a {
  position: absolute;
  top: 0;
  right: 5px;
  background: red;
  width: 20px;
  height: 20px;
  color: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 12px;
  border-radius: 100%;
  text-align: center;
}

button.btn.btn-success {
  background: #274a70 !important;
}

.profile-left {
  padding: 10px;
  background: #fff;
  box-shadow: 0px 5px 18px -11px rgba(150, 150, 150, 0.8);
}

.profile-left img {
  margin-bottom: 15px;
}

.profile-left h3,
.profile-left ul li {
  color: rgba(0, 0, 0, 0.6);
}

ul.social-list {
  display: -webkit-flex;
  display: flex;
  margin-top: 20px !important;
}

ul.social-list li {
  width: 40px;
  height: 40px;
  background: #274a70;
  border-radius: 100%;
  text-align: center;
  line-height: 40px;
  margin-right: 10px;
}

ul.social-list li a {
  color: #fff;
}

.udb {
  position: relative;
  overflow: hidden;
}

.udb-sec {
  position: relative;
  overflow: hidden;
  margin-bottom: 35px;
  background: #fff;
  padding: 25px;
  border-radius: 2px;
  box-shadow: 0px 5px 18px -11px rgba(150, 150, 150, 0.8);
  border: 1px solid #f3f2f2;
}

.udb-sec h4 {
  padding-bottom: 10px;
  border-bottom: 1px solid #eeeeef;
  margin-bottom: 15px;
  text-transform: uppercase;
  color: #203245;
}

.sdb-tabl-com tr {
  color: #969595;
  border-bottom: 1px solid #d0d0d0;
}

.sdb-tabl-com tr td {
  font-size: 15px;
  color: #333;
  padding: 14px 14px 14px 0px;
}

.sdb-bot-edit {
  margin-top: 25px;
}

section.teacher-profile-section.section-padding {
  background: #f4f4f4;
}

.profile-left li.list-group-item {
  padding: 5px;
  margin: 10px 0;
  position: relative;
}

.edu-main ul {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.edu-main ul li.d-flex {
  width: 100%;
  font-size: 13px;
}

.edu-main ul li.d-flex p {
  margin: 0;
  margin-right: 5px;
}

.profile-left h3,
.profile-left ul li {
  color: #000;
}

.close-edu {
  position: absolute;
  top: 5%;
  right: 5%;
}

.profile-buttons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.profile-buttons a {
  height: 50px;
  line-height: 24px;
  margin: 10px;
}

@media print {
  .myDivToPrint {
    background-color: white;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    padding: 15px;
    font-size: 14px;
    line-height: 18px;
    z-index: 9999999;
  }

  .hhh {
    display: none;
  }
}

a.theme-line-button.hhh {
  padding: 15px;
  line-height: 1;
  border-radius: 5px;
}

.pro-pic img {
  width: 150px;
}

.profile-buttons.d-flex {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.profile-extra-info {
  margin-top: 25px;
}
.sdb-bot-edit.thth {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.thth .theme-line-button {
  line-height: 18px;
  margin-top: 15px;
}

a.cose-delete.text-red.position-absolute {
  top: 5px;
  right: 4px;
  font-size: 12px;
  color: red;
}

.video-list.my-profile {
  padding-right: 50px;
}

h3.mb-0.red-text.d-flex.justify-content-between.align-items-center {
  font-size: 24px;
  line-height: 1;
}

.video-list.my-profile.position-relative h4 {
  font-size: 20px;
}

.video-list {
  padding: 11px;
}

.card-header {
  padding: 5px 1.25rem;
}

.card-header {
  padding: 0px 1.25rem;
}

.pp-section {
  background: #dfdfdf;
  padding: 25px 0;
}

.container.grd-setting-page {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.panel-heading.panel-heading-01 {
  background: #274a70;
  padding: 10px 5px;
  color: #fff;
  border-radius: 5px;
}

.col-md-12.text-center {
  margin: 50px 0;
}

.panel-body-02 {
  padding: 15px;
  border: 1px solid #ddd;
}

tr.trtr {
  border-bottom: 1px solid #ddd;
}

section.profile-sitting-section {
  background: #dfdfdf;
}

nav.nav.nav-tabs .nav-link {
  padding: 5px 0;
  background: #fff;
  margin: 5px;
  box-shadow: 3px 3px 3px #ddd;
  padding: 8px 20px;
}

.nav-tabs {
  border-bottom: none;
  margin-bottom: 20px;
}

.profile-sitting-section h6 {
  margin: 0;
}

.profile-sitting-section .card {
  margin-bottom: 25px;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #274a70 !important;
  color: #fff;
}

.status-time-btn-area.text-right {
  margin-bottom: 20px;
}

.status-content {
  background: #fff;
  padding: 20px 25px;
}

.box {
  background: #ddd;
  padding: 15px;
}

.box h6 {
  font-weight: 700 !important;
}

.status-title {
  padding: 15px;
}

.quiz-item {
  background: #fff;
  padding: 20px;
  margin-bottom: 20px;
}

.quiz-item h6 {
  font-weight: 700;
  margin-bottom: 20px;
}

.profile-sitting-section .custom-control {
  margin-bottom: 0;
}

.pi-section {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.forbg i {
  font-size: 60px;
  color: #cacaca;
}

.col-lg-8.forbg {
  background: #fff;
  padding: 100px;
}

.section-padding-50 {
  padding: 50px 0;
}

.section-padding-20 {
  padding: 20px 0;
}

.react-countdown-clock {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 999999999999;
}

.new-teacher-sitting {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.new-teacher-sitting nav.nav.nav-tabs .nav-link {
  width: 100%;
}

.new-teacher-sitting nav.nav.nav-tabs .nav-link {
  background: #fff;
  margin: 5px;
  box-shadow: 0px 0px 1px #000;
  padding: 8px 13px;
  border-radius: 0;
  margin-bottom: 1px;
  color: #000;
  font-weight: 500;
}

.new-teacher-sitting nav.nav.nav-tabs {
  width: 25%;
}

.new-teacher-sitting .tab-content {
  width: 75%;
}

.tab-content {
  padding: 1rem;
  padding-top: 0;
}

body .theme-solid-button {
  border-radius: 5px !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background: #274a70 !important;
  color: #fff !important;
}

.react-countdown-clock {
  position: fixed;
  top: 211px;
  right: 307px;
  z-index: 999999999999;
}

.udb-sec.udb-prof h4 {
  background: #274a70;
  color: #fff;
  padding: 15px;
}

li.default-colum {
  display: -webkit-flex;
  display: flex;
  border: 1px solid #ddd;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  border-radius: 5px;
  margin-bottom: 5px;
}

li.default-colum span.colum-name {
  background: #e9ecef;
  width: 25%;
  font-weight: 500;
  border-radius: 5px 0px 0px 5px;
}

li.default-colum span {
  padding: 8px 0;
  padding-left: 15px;
}
textarea.form-control {
  height: 150px;
}
.accademic-sitting .card-body{
  padding: 0;
}
.accademic-sitting .table {
  width: 100%;
  margin-bottom: 0; 
}
.card.input-ffrm {
  padding: 20px;
}
.profile-sitting-section .card {
  margin-bottom: 10px;
}
.md-form.md-outline label{
  font-weight: 500;
}
@media only screen and (max-width: 768px) {
  li.default-colum span.colum-name {
    width: 39%;

  }

  li.default-colum span {
    font-size: 13px;
  }

  .profile-left {
    text-align: center;
  }
}
.work-details {
  background: #f9f9f9;
}
.work-details .section-header {
  padding: 15px 16px 10px;
  background: #f1f1f1;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
}
.work-details .section-wrapper {
  width: 100%;
  background: #fff;
  box-shadow: 0px 1px 5px #ddd;
  padding: 25px;
}
.vacancy {
  width: 100%;
  color: #fff;
  padding: 14px 20px 5px;
  border-radius: 4px 5px 0px 0px;
}
.work-gp {
  display: -webkit-flex;
  display: flex;
}
.work-first {
  width: 100%;
  border-right: 1px solid #ddd;
}
.work-first span {
  font-weight: 700;
}
.work-first .job-info {
  list-style: disc;
  padding-left: 25px;
  margin-bottom: 20px;
}
.work-first .job-info li {
  list-style: disc;
}
.work-bottom {
  padding: 30px 0;
}
.skills {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.skills li {
  background-color: #ddd;
}

.skills li {
  padding: 3px 13px;
  margin: 5px;
  border-radius: 1px;
  font-size: 13px;
}
.job-sum-header {
  color: #fff;
  padding: 14px 20px 5px;
  border-radius: 4px 5px 0px 0px;
}
.job-sum-wrapper {
  background: #f3f3f3;
  border: 1px solid #ddd;
  padding: 15px 15px;
}
.job-sum-wrapper ul li {
  padding: 5px 0;
}
.job-sum-wrapper ul li span {
  font-weight: 700;
}
.sub-job {
  text-align: center;
  padding: 20px 0;
}
.social-link-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 15px 0;
}
.sub-job .social-link-list li {
  margin: 0 8px;
  width: 52px;
  height: 25px;
  padding: 2px 0;
}
.facebook {
  background: #3a589e;
}
.sub-job .social-link-list li a {
  color: #fff;
}
.twitter {
  background: #429cd6;
}
.linkedin {
  background: #2e89c1;
}
.google {
  background: #df4b37;
}
.youtube {
  background: #cd2029;
}
a.border-raius-less.theme-solid-button.mt-4 {
  border-radius: 0;
}

section.askfortutor-section {
  background: #f4f4f4;
}
.aft-main {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
}
.section-header {
  margin: auto;
  text-align: center;
  padding-bottom: 10px;
}
.new-grid {
  width: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  margin-bottom: 25px;
  /* border: 1.5px solid blue; */
}
.new-top {
  position: relative;
  /* padding: 1.5em; */
  background: #fff;
}
.new-top a img {
  height: 333px;
  width: 100%;
}
.new-text {
  background-color: #16a085;
  padding: 1em;
  position: absolute;
  top: 25%;
  width: 80%;
  left: -100%;
  transition: 0.5s all;
}
.new-text ul li {
  display: block;
  text-align: center;
}
.new-text ul li a {
  border: 1px solid #fff;
  padding: 0.8em 1em;
  display: block;
  text-decoration: none;
  color: #fff;
  font-size: 1em;
  font-weight: 500;
}
.popular-item {
  margin: 0.8%;

  width: 23.4%;
  padding: 0 !important;
}
.popular-item .new-bottom {
  padding: 1em;
}

.new-bottom {
  padding: 1em;
  background: #fff;
  /* position: absolute; */
  bottom: 0px;
}
.new-bottom h5 {
  font-size: 1.6em;
  font-weight: 700;
  display: inline-block;
  height: 50px;
}
.new-bottom h5 a.name {
  color: #353f49;
}
.ofr p {
  font-size: 1.1em;
  color: #353f49;
  display: inline-block;
  margin-top: 0.7em;
  font-weight: 600;
}
.rating {
  text-align: center;
  float: right;
  margin-top: 0.7em;
}
.rating span {
  display: inline-block;
  position: relative;
  font-size: 1.3em;
  color: #555;
}
.new-grid:hover .new-text {
  left: 10%;
}
.leftMenuArea > h2 {
  font-size: 14px;
  font-weight: 400;
  color: #444546;
  text-align: left;
  text-transform: capitalize;
  margin: 30px 0 0;
  margin-bottom: 25px;
}

.bookSubject {
  background-color: #f3f3f3;
  margin-bottom: 20px;
}

.bookSubjectCaption {
  background-color: #d8d8d8;
  height: 30px;
  overflow: hidden;
}

.bookSubject i {
  font-size: 50px;
  margin: 35px;
  color: #a6cb78;
}

.bookSubjectCaption h2 {
  color: #444546;
  font-size: 13px;
  text-align: center;
  margin: 0;
  padding: 8px 0;
}

.dropdown-list-area {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin: 20px 0 0;
}

.dropdown-list-area .btn-primary.dropdown-toggle {
  background-color: #274a70 !important;
}

.dropdown-list-area .btn-unique {
  background-color: #274a70 !important;
}

.dropdown-list-area.subcategory {
  box-shadow: 0px 0px 9px #ddd;
  padding-right: 20px;
}

.dropdown-list-area.subcategory .btn-primary.dropdown-toggle {
  background-color: #fff !important;
  color: #444 !important;
  box-shadow: none;
}

.dropdown-list-area .btn-primary.dropdown-toggle:first-child {
  margin-left: 0;
}

.booksale-cover-section .tab-content {
  padding: 0;
}

button.btn.btn-primary.Ripple-parent.dropdown-toggle {
  padding: 8px 47px;
}

.dropdown-list-area.subcategory button.btn.btn-primary.Ripple-parent.dropdown-toggle {
  padding: 8px 46px;
}

.dropdown-list-area.subcategory .md-form {
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
}

.dropdown-list-area.subcategory .btn-primary.dropdown-toggle {
  background-color: #274a70 !important;
  color: #fff !important;
}

.dropdown-list-area .md-form {
  margin-bottom: 0;
}

.booksoring-filter {
}

.booksoring-filter .card-header h5 {
  text-align: left;
  color: #363636;
  line-height: 20px;
  padding: 15px 15px 17px;
  border-bottom: 1px solid #dcdcdc;
  margin: 0;
}

.booksoring-filter .card-header {
  padding: 0;
  background: inherit;
}

.booksoring-filter .card-body {
  padding: 0.25rem 20px;
  height: 200px;
  overflow-y: scroll;
}

.sblink {
  display: block;
}

.new-top a img {
  height: 180px;
  width: 100%;
}

.new-bottom h5 {
  font-size: 16px;
  height: inherit;
}

.new-bottom ul li {
  font-size: 14px;
}

.ofr p {
  margin-top: 0;
  font-size: 14px;
}

.rating {
  text-align: center;
  float: inherit;
  margin-top: 0;
}

.new-text {
  width: 100%;
  padding: 0;
  background: #274a70;
}

.new-grid:hover .new-text {
  left: 0;
}

.ofr p {
  margin-bottom: 0;
}

.new-bottom {
  padding: 9px;
}

.bk-srting-area {
  padding: 0px 10px;
  box-shadow: 0px 0px 3px #ddd;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  text-align: center;
}

.wowow {
  border: 1px solid #ddd;
  padding: 20px;
}
.single-rating {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 15px 0;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.single-top-left h6 {
  font-size: 2em;
  color: #353f49;
  font-weight: 700;
  font-family: "Bree Serif", serif;
}
.single-rating p,
.single-rating a {
  border: 1px solid #444;
  padding: 1px 18px;
  border-radius: 5px;
}
.single-rating a {
  margin: 0;
}
.btn_form {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 25px 0;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
a.theme-solid-button.sm.m {
  padding: 10px 32px;
  line-height: 13px;
}
ul.size,
ul.color {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #ddd;
  padding: 0 25px;
  margin-bottom: 10px !important;
}
ul.size p,
ul.color p {
  margin-bottom: 0;
}
.accordion__title {
  text-align: center;
}
.accordion__title h3 {
  font-size: 25px;
}
.book-accortion {
  padding-bottom: 100px;
}
.book-sell-details-section {
  padding-top: 100px;
}
.accordion {
  width: 100%;
  margin-top: 25px;
}

.tutorial-search-header {
  width: 100%;
  padding: 0 0 20px;
}
.search-category {
  display: -webkit-flex;
  display: flex;
}
.category-header {
  background: #fff;
  padding: 12px 0 4px 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  box-shadow: 3px 3px 3px #ddd;
  width: 100%;
}
.category-items {
  width: 100%;
}
.row.category-main-item {
  width: 100%;
}
.product-item {
  box-shadow: 0px 0px 2px 1px #ddd;
  margin-bottom: 15px;
  border-radius: 4px;
}
.product-thumb {
  position: relative;
  overflow: hidden;
}
.product-overly {
  position: absolute;
  top: 50%;
  text-align: center;
  width: 100%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
}
.product-overly .view {
  opacity: 0;
  -webkit-transform: translate(-186px);
          transform: translate(-186px);
  background-color: #c0392b;
}

.product-overly .view,
.product-overly .order {
  padding: 12px 40px;
  border-radius: 5px;
  color: #fff;
  font-size: 18px;
  display: inline-block;
  transition: 1s;
}
.product-overly .order {
  opacity: 0;
  -webkit-transform: translate(186px);
          transform: translate(186px);
  background-color: #3498db;
}
.product-thumb:hover .product-overly .view,
.product-thumb:hover .product-overly .order {
  opacity: 1;
  -webkit-transform: translate(0);
          transform: translate(0);
}
.product-thumb:hover .product-overly .view,
.product-thumb:hover .product-overly .order {
  opacity: 1;
  -webkit-transform: translate(0);
          transform: translate(0);
}
.product-content {
  padding: 25px 20px;
}
.product-content-top {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

main.category-main-area.tutorials-section .product-top-content {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-align-items: center;
          align-items: center;
}
main.category-main-area.tutorials-section .product-top-content div {
  text-align: center;
}
main.category-main-area.tutorials-section .product-top-content div img {
  border-radius: 100%;
}
.star-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.star-list li {
  padding: 0 2px;
}
.star-list li a i {
  color: gold;
}
.star-list li a i {
  font-size: 20px;
}

.product-content-top a i {
  line-height: 40px;
  font-size: 20px;
}
main.category-main-area .product-content-bottom {
  padding: 17px 0 0;
}

.product-content-bottom {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 25px 0;
  margin-bottom: 10px;
}
.product-content-button {
  text-align: center;
}

section.tutorial-banner-section {
  width: 100%;
  background: url("http://www.bengalsoftware.com/assets/images/tutorial/tutorial-bg.jpg") no-repeat center center/cover;
}

.tutorial-banner-content {
  margin-top: 111px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 25px;
}

.tutorial-banner-content img {
  border-radius: 100%;
  margin-right: 20px;
}

section.tutorial-tab-section {
  padding: 50px 0;
}

.tutorial-tab {
  width: 100%;
}

.hire-profile {
  border: 2px solid #ddd;
  padding: 25px 10px;
  text-align: center;
}

.hire-profile img {
  text-align: center;
  width: 88%;
  margin: 0 auto;
  display: inherit;
  background: #fff;
  border: 2px solid #999;
  padding: 7px;
  border-radius: 8px;
}

.hire-profile .hire-btn {
  padding: 5px 30px;
  color: #fff;
  margin: 10px 0;
  border-radius: 4px;
}

.social-link-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  padding: 15px 0;
}

.hire-profile .social-link-list li:nth-child(1) {
  background: #3d5999;
}

.hire-profile .social-link-list > li {
  margin: 0 5px;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 100%;
  text-align: center;
  padding: 0;
}

.hire-profile .social-link-list li:nth-child(2) {
  background: #60a9dd;
}

.hire-profile .social-link-list li:nth-child(3) {
  background: #0777b6;
}

.hire-profile .social-link-list li a {
  color: #fff;
}

.others-service {
  border: 1px solid #4444;
  margin: 25px 0;
}

.others-service ul {
  text-align: center;
}

.others-service ul li {
  padding: 10px 0;
  border-bottom: 1px solid #999;
}

.others-service ul li p {
  margin: 0;
}

.others-service ul li span {
  color: #999;
}

.porfolio-show ul li a {
  width: 100%;
  margin: 5px 0;
}

section.tutorial-tab-section iframe {
  max-width: 426px;
  height: 250px;
  float: left;
  margin-right: 12px;
}

.learning-info {
  display: -webkit-flex;
  display: flex;
  background: #f1f1f1;
  border: 2px solid #ddd;
  padding: 20px 15px;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.learning-info h2 {
  width: 100%;
}

.learning-item {
  width: 50%;
  padding-right: 5px;
}

.learning-item ul li {
  margin: 14px 0;
}

.learning-item ul li i {
  margin-right: 7px;
}

.custom-select {
  display: block !important;
}

section.tutorial-tab-section iframe {
  max-width: 100%;
  height: 390px;
  float: left;
  margin-right: 12px;
}

.md-accordion .card .card-header {
  border-bottom: 0;
  padding: 0rem 1.5rem;
  background: transparent;
}

.main-video-item.w-100 {
  display: inline-block;
  width: 100%;
  border: 1px solid #ddd;
  padding: 5px;
  border-radius: 5px;
  height: 400px;
}

.video-list {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  padding: 15px;
  box-shadow: 0px 0px 7px #ddd;
  margin-bottom: 10px;
  cursor: pointer;
}

.video-list h4 {
  margin: 0;
}

.video-list i {
  font-size: 25px;
}

ul.comment-lists {
  margin-top: 5px !important;
}

ul.comment-lists > li {
  border-bottom: 1px solid #ddd;
  margin-bottom: 25px;
}

.root-comment {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.user-info {
  display: -webkit-flex;
  display: flex;
  width: 30%;
}

.review-area {
  width: 70%;
}

.user-info-thumb {
  width: 50px;
  margin-right: 25px;
}

.user-info-thumb img {
  border-radius: 100%;
}

.md-accordion .card .fa-angle-down {
  float: right;
  font-size: 20px;
  color: #274a70 !important;
}

.md-accordion .card .fa-angle-up {
  float: right;
  font-size: 20px;
  color: #274a70 !important;
}

.md-accordion .card .card-header {
  padding: 1rem 1.5rem;
  border-radius: 0;
  border: solid 1px #e8e9eb;
  background: #274a70;
  margin-bottom: 5px;
  color: #fff;
}

.md-accordion .card .card-header h3 {
  font-size: 16px;
}

.text-bold {
  font-weight: 700;
}

.card-body {
  -webkit-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 0.25rem 0;
}

.comment-root {
  background: #ddd;
  padding: 20px;
}

.comment-form {
}

.skills li {
  background-color: #274a70;
  color: #fff;
}

ul.social-nav {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
ul.social-nav li {
  margin: 0 5px;
}

.c-card.br {
  /* border-right: 1px solid #ddd; */
}

.c-card {
  padding: 0 15px 20px;
}

.hotJobsCompany {
  background-color: #f5f5f5;
  cursor: pointer;
}

.hotJobsCompany {
  padding: 15px 0 10px;
  display: -webkit-flex;
  display: flex;
}

.companyDetails h5 {
  margin: 0 0 5px;
  color: #2f64a3;
  font-weight: 400;
  font-size: 13px;
}

.companyDetails ul li a {
  color: #616161;
  font-size: 12px;
  text-transform: none;
}

.cursor {
  cursor: pointer;
}

.modal-dialog {
  max-width: 1170px;
  margin: 1.75rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
  width: 100%;
}

.modal-title .row {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.header-left img {
  width: 100px;
}

h4.modal-title {
  padding: 0 35px;
}

.header-left {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.header-left img {
  width: 198px;
}

section.study-abroad-section {
  padding-bottom: 450px;
}

.companyLogo img {
  height: 55px;
}

.hotJobsCompany {
  margin-bottom: 20px;
}

footer {
  background: #222;
  padding: 30px 0 0;
}

footer p,
footer a,
footer li {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin: 10px 0;
}

footer h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}

footer img {
  margin-bottom: 15px;
  width: 154px;
  height: 100px;
}

ul#contact_details_footer li i {
  margin-right: 10px;
}

#social_footer {
  border-top: 1px dotted rgba(255, 255, 255, 0.2);
  padding-top: 30px;
  text-align: center;
}

#social_footer ul {
  margin: 0;
  padding: 0 0 10px;
}

#social_footer ul li {
  display: inline-block;
  margin: 0 5px 10px;
}

#social_footer ul li a {
  color: #fff;
  line-height: 36px;
  display: block;
  font-size: 16px;
  width: 35px;
  height: 35px;
  background-color: #333;
  border-radius: 50%;
}

.url-input {
  display: -webkit-flex;
  display: flex;
}

.url-input .md-form.md-outline {
  width: 90%;
}

.d {
  width: 100%;
}

.row.three-row .team-item:hover .team-thumb img {
  -webkit-transform: scale(0.5) translate(-190px, -205px);
  transform: scale(0.5) translate(-274px, -205px);
}

.row.three-row .team-item:hover .team-contant h3 {
  -webkit-transform: rotate(90deg) translate(-60px, 90px);
  transform: rotate(90deg) translate(-66px, 147px);
}

.team-content-overlay a.btn.sm.white {
  margin-top: 30px !important;
}

ul.developer-s-info li {
}

.search-category.tutor-list .input-group.mb-3 {
  margin-right: 10px;
}

.search-category.tutor-list .input-group.mb-3:last-child {
  margin-right: 0;
}

.root-spinner {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  height: 70vh;
}

.text-primary {
  color: #274a70 !important;
}

.spinner-grow {
  display: inline-block;
  width: 5rem;
  height: 5rem;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: spinner-grow 0.75s linear infinite;
  animation: spinner-grow 0.75s linear infinite;
}

.profile-left a.theme-line-button.hhh {
  padding: 10px 25px;
  margin-top: 15px;
  display: inline-block;
}
section.askfortutor-section {
  background: #f4f4f4;
}
.aft-main {
  background: #fff;
  padding: 20px;
  border: 1px solid #ddd;
}

.custom-select,
.form-control {
  border-width: 2px;
  display: block !IMPORTANT;
}

.confirm-books-area .cancel-content h5 {
  font-size: 14px;
}

.confirm-books-area .cancel-content h3 {
  font-size: 15px;
}

.confirm-books-area .cancel-thumb {
  height: 50px;
  width: 40px;
}

.confirm-books-area .cancel-item {
  padding: 10px 15px;
  margin: 5px 0;
}

.total-price-area {
  border-top: 2px solid #ddd;
  margin-top: 15px;
  padding-top: 8px;
}

.cancel-item {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  background: #fff;
  padding: 20px 15px;
  border-radius: 6px;
  box-shadow: 0 0 16px #ddd;
  margin: 15px 0;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.cancel-content {
  width: 50%;
}

.cancel-thumb {
  width: 20%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
a.bg-color.theme-line-button {
  display: inline-block;
}
.confirm-books-area {
  padding-top: 40px;
}
.author-item {
  text-align: center;
  margin-bottom: 30px;
}

.author-thumb {
  height: 150px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  border-radius: 100%;
  width: 150px;
  border: 3px solid #ddd;
}

.author-thumb i {
  font-size: 40px;
}

.category-item {
  background: #274a70;
  padding: 20px 0;
  text-align: center;
  border-radius: 5px;
  margin-bottom: 30px;
}

.category-item h4 {
  font-size: 16px;
  color: #fff;
}

