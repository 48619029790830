@import url("https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i|Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i");

body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0;
}

img {
  max-width: 100%;
}

body .theme-solid-button {
  line-height: 48px;
  border-width: 1px;
  border-radius: 20px;
  padding: 12px 40px;
  font-size: 18px;
  border-style: solid;
  border-color: #274a70;
  color: #fff;
  background: #274a70;
  transition: all ease-in-out 0.4s;
}

.navbar-nav {
  align-items: center;
}

body .theme-line-button {
  line-height: 48px;
  border-width: 1px;
  border-radius: 20px;
  padding: 0 40px;
  font-size: 18px;
  color: #274a70;
  border-style: solid;
  border-color: #274a70;
  padding: 12px 40px;
  transition: all ease-in-out 0.4s;
}

body .theme-line-button:hover {
  color: #fff;
  background: #274a70;
}

.theme-solid-button:hover {
  color: #274a70;
  border-color: #274a70;
  background: transparent;
}

.section-padding {
  padding: 100px 0;
}

.mark-text {
  color: #274a70;
}

.section-header {
  margin: auto;
  text-align: center;
  padding-bottom: 40px;
}

a.theme-solid-button.nr {
  padding: 13px 20px !important;
  font-size: 15px;
  border-radius: 5px;
  line-height: 1.5;
}

.primary-bg {
  background: #274a70;
}
