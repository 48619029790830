footer {
  background: #222;
  padding: 30px 0 0;
}

footer p,
footer a,
footer li {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 500;
  margin: 10px 0;
}

footer h3 {
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 25px;
}

footer img {
  margin-bottom: 15px;
  width: 154px;
  height: 100px;
}

ul#contact_details_footer li i {
  margin-right: 10px;
}

#social_footer {
  border-top: 1px dotted rgba(255, 255, 255, 0.2);
  padding-top: 30px;
  text-align: center;
}

#social_footer ul {
  margin: 0;
  padding: 0 0 10px;
}

#social_footer ul li {
  display: inline-block;
  margin: 0 5px 10px;
}

#social_footer ul li a {
  color: #fff;
  line-height: 36px;
  display: block;
  font-size: 16px;
  width: 35px;
  height: 35px;
  background-color: #333;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
