.sdb-bot-edit.thth {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.thth .theme-line-button {
  line-height: 18px;
  margin-top: 15px;
}

a.cose-delete.text-red.position-absolute {
  top: 5px;
  right: 4px;
  font-size: 12px;
  color: red;
}

.video-list.my-profile {
  padding-right: 50px;
}

h3.mb-0.red-text.d-flex.justify-content-between.align-items-center {
  font-size: 24px;
  line-height: 1;
}

.video-list.my-profile.position-relative h4 {
  font-size: 20px;
}

.video-list {
  padding: 11px;
}

.card-header {
  padding: 5px 1.25rem;
}

.card-header {
  padding: 0px 1.25rem;
}

.pp-section {
  background: #dfdfdf;
  padding: 25px 0;
}

.container.grd-setting-page {
  background: #fff;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
}
